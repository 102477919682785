<template>

<app-content>

	<app-content-head title="Overview" subtitle="See an overview of your registration revenue, platform fees, and payouts.">

	</app-content-head>

	<app-content-body>

		<div class="summary-wrapper">

			<app-content-summary title="Transactions & Fees" icon="registration" :grow="true">

				<app-content-summary-item label="Payments" :grow="true" :value="$org.tracking.billing.transactions.payments | formatMoney" />
				<app-content-summary-item label="Refunds" :grow="true" :value="$org.tracking.billing.transactions.refunds | formatMoney('-')" />
				<app-content-summary-item label="Platform Fees" :grow="true" :value="$org.tracking.billing.fees.platform | formatMoney('-')" />
				<app-content-summary-item label="Net Revenue" :grow="true" :value="$org.tracking.billing.net | formatMoney('=')" />

			</app-content-summary>

			<app-content-summary title="Payouts" icon="payouts">
 
				<app-content-summary-item label="Sent" :value="$org.tracking.billing.payouts.sent | formatMoney" />
				<app-content-summary-item label="Pending" :value="$org.tracking.billing.payouts.pending | formatMoney" />

			</app-content-summary>

		</div>

	</app-content-body>

</app-content>

</template>

<script>

export default {

}

</script>

<style scoped>

.summary-wrapper {
	display: flex;
}

</style>